<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        For each of the reaction orders listed below, determine the proper units for the rate
        constant,
        <stemble-latex content="$\text{k.}$" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th></th>
            <th v-for="unit in units" :key="unit.value">
              <stemble-latex :content="unit.text" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, i) in orders" :key="order.value">
            <td>
              <stemble-latex :content="order.text" />
            </td>
            <td v-for="unit in units" :key="unit.value" style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio :key="unit.value" :value="unit.value" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question90',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      orders: [
        {text: '$\\text{Zeroth Order}$'},
        {text: '$\\text{First Order}$'},
        {text: '$\\text{Second Order}$'},
        {text: '$\\text{Third Order}$'},
      ],
      units: [
        {text: '$\\text{M}^{2}\\text{/s}$', value: 'M2persec'},
        {text: '$\\text{M/s}$', value: 'M1persec'},
        {text: '$\\text{M}^{2}$', value: 'M2'},
        {text: '$\\text{ s}^{-1}$', value: 'persec'},
        {text: '$\\text{M}^{-2}$', value: 'M-2'},
        {text: '$\\text{M}^{-1}\\text{ s}^{-1}$', value: 'M-1persec'},
        {text: '$\\text{M}^{-2}\\text{ s}^{-1}$', value: 'M-2persec'},
      ],
    };
  },
};
</script>
